import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import SuccessHeader from "../components/sections/success-header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import Contact from "../components/sections/contact"
import CookieScript from "../components/common/cookie-script"

const IndexPage = () => (
  <Layout>
    <SEO title="Success - New Install" />
    <Navigation />
    <SuccessHeader />
    <Features />
    <Contact />
    <Footer />
    <CookieScript />
  </Layout>
)

export default IndexPage
